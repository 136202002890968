<template>
    <div>
        Second
    </div>
</template>
<script>
export default {
    created() {
        console.log('second created');
    },
    mounted() {
        console.log('second mounted');
    },
    activated() {
        console.log('second activated');
    },
    deactivated() {
        console.log('second deactivated');
    },
    destroyed() {
        console.log('second destroyed');
    }
}
</script>
